import React from "react"
import { navigate } from "gatsby"

const SearchForm = ({ query }) => (
  <form role="search" method="GET" onSubmit={e => { 
      e.preventDefault()
      //document.getElementById("search-input").blur();
      navigate(`/?keywords=${encodeURIComponent(document.getElementById("search-input").value)}`);
  }}>
    <label htmlFor="search-input">
      <h1>Test Search</h1>
    </label>
    <div className="field rc-padding">
      <input
        type="search"
        id="search-input"
        name="keywords"
        className="input search-bar"
        placeholder="Enter keywords here"
        aria-controls="search-results-count"
        autoFocus
      />
      <input type="submit" value="Search" className="button is-primary submit-button" />
    </div>
  </form>
)

export default SearchForm
