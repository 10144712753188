import React, { useState, useEffect } from "react";
import debounce from "lodash.debounce";
import Layout from "../components/Layout";
import SearchForm from "../components/SearchForm";
import SearchResults from "../components/SearchResults";
import { graphql } from "gatsby";
import "url-search-params-polyfill";
import { Helmet } from "react-helmet";

const Search = ({ data, location }) => {
  const [results, setResults] = useState([]);
  const searchQuery =
    new URLSearchParams(location.search).get("keywords") || "";

  useEffect(() => {
    if (searchQuery && window.__LUNR__) {
      (async () => {
        const lunr = await window.__LUNR__.__loaded;
        const match = lunr.en.index.search(searchQuery, searchQuery + "*");
        const wildcard = lunr.en.index.search("*" + searchQuery + "*");
        const refs = match.length !== 0 ? match : wildcard;
        const tests = refs.map(({ ref }) => lunr.en.store[ref]);

        setResults(tests);
      })();
    }

    if (!searchQuery) setResults([]);
  }, [location.search, searchQuery]);

  return (
    <Layout location={location} title={data.site.siteMetadata.title}>
      <Helmet titleTemplate="%s | Pathlab">
        <title>{`Test Guide`}</title>
        <meta
          name="description"
          content={`Guide for testing offered at Pathlab.`}
        />
      </Helmet>
      <section className="section">
        <div className="container content-border">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              {/* <div className="content basic-border news-item has-text-centered banner news-header action-banner">
                <h1 className="action-header">Industrial Strike Action</h1>
                <div className="has-text-weight-bold is-size-4">
                  <p className="action-header">
                    Wednesday 5th & Friday 7th February
                  </p>
                </div>
                <div className="has-text-weight-bold is-size-4">
                  <p className="action-header">
                    From 6am Monday, 3rd February, all Microbiology services are
                    limited to Life Preserving Services (LPS).
                  </p>
                </div>
                <div className="has-text-weight-bold is-size-5">
                  <p>
                    <a
                      className="has-text-primary"
                      href="https://www.pathlab.co.nz/static/f0ce8f1896c51b4f7c1a0039d133f278/2502%20Specimen%20Storage%20Guidelines%20for%20Practices.pdf"
                    >
                      Specimen storage guidelines over the industrial action
                      period.
                    </a>
                  </p>
                </div>
              </div> */}

              <div className="content browser-ie">
                <p>Your browser is lacking modern features.</p>
                <p>
                  For a modern search experience, please use Firefox, Chrome or
                  Edge to view this page.
                </p>
                <a
                  href="https://www.google.com/search?q=site%3Atests.pathlab.co.nz"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="has-text-primary"
                >
                  <strong>
                    Alternatively, perform a Google Search for content from our
                    site.
                  </strong>
                </a>
              </div>
              <div className="content browser-modern">
                <SearchForm query={searchQuery} />
                <br />
                <SearchResults query={searchQuery} results={results} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Search;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
